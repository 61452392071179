import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { Link } from "gatsby"
import styled from "styled-components"
import { mq, typography } from "styles"

export const Card = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  background-color: #fefbf1;
  padding: 24px 30px;
  margin-bottom: 16px;
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.08);
  ${mq.minWidth("sm")} {
    padding: 32px;
  }
`

export const CardHeader = styled.div<{ bottomLine?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 37px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.localTheme?.text?.primary?.opacityHex}`};
  padding-bottom: 12px;
  font-size: 14px;
`

export const CardTitle = styled.div`
  flex: 1;
  font-weight: bold;
`

export const CardSubtitle = styled.div`
  flex: 1;
`

export const CardBody = styled.div`
  margin-bottom: 24px;
  ${mq.minWidth("md")} {
    margin-bottom: 32px;
  }
`

export const CardLinkButton = styled(NoStyleButton)`
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  text-decoration: underline;
`

export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 24px;
  line-height: 22px;
  form {
    width: 100%;
  }
`

export const CardButton = styled(NoStyleButton)`
  color: ${({ theme }) => theme.dark?.text?.linksText?.opacityHex};
  padding: 4px 8px;
  border-radius: 5000px;
  border: 1px solid ${({ theme }) => theme.dark?.text?.linksText?.opacityHex};
  font-size: 13px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) =>
      theme?.dark?.canvas?.secondary?.opacityHex};
  }
  ${mq.minWidth("sm")} {
    font-size: 14px;
  }
`

export const CardLink = styled(Link)`
  ${typography.defaultLink};
  display: inline-block;
  font-size: inherit;
`

export const UserData = styled.div``

export const AddressHeading = styled.div`
  ${typography.body};
  font-weight: bold;
  display: flex;
`

export const AddressItem = styled.div`
  display: flex;
`

export const Divider = styled.div`
  height: 1px;
  border-bottom: 1px solid var(--button-primary-dark-fill);
`
