import { Dropdown } from "components/UI/Dropdown/Dropdown"
import React from "react"
import { ErrorContainer, ErrorIcon, ErrorMessage } from "./Form.styles"
import {
  AddressDropdown,
  AddressDropdownItem,
  AddressDropdownList,
} from "./ProvinceCodeSelect.styles"

type ProvinceCodeSelectProps = {
  error?: string
  touched: boolean
  value: string
  options: string[]
  placeholder: string
  onChange: (value: string) => void
}

export const ProvinceCodeSelect: React.FC<ProvinceCodeSelectProps> = ({
  error,
  touched,
  value,
  options,
  placeholder,
  onChange,
}) => (
  <>
    <AddressDropdown $hasError={error && touched} $hasValue={!!value}>
      <Dropdown
        renderButton={value || placeholder}
        renderList={
          <AddressDropdownList>
            {options.map(code => (
              <AddressDropdownItem
                aria-label={code}
                key={code}
                onClick={() => {
                  onChange(code)
                }}
              >
                {code}
              </AddressDropdownItem>
            ))}
          </AddressDropdownList>
        }
      />
    </AddressDropdown>
    {error && touched ? (
      <ErrorContainer>
        <ErrorIcon />
        <ErrorMessage>{error}</ErrorMessage>
      </ErrorContainer>
    ) : null}
  </>
)
