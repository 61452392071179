import { useAuth0 } from "@auth0/auth0-react"
import { DeleteButton } from "components/Account/Settings.styles"
import { Button } from "components/UI/Button/Button"
import React, { useRef, useState } from "react"
import { Content, Wrapper } from "./AccountView.styles"
import {
  Card,
  CardBody,
  CardContent,
  CardHeader,
  CardLinkButton,
  CardTitle,
} from "./Card.styles"
import { ColumnsWrapper } from "./Form.styles"
import { Header } from "./Header"

export const Settings: React.FC<{ path?: string }> = () => {
  const [isEditing, setIsEditing] = useState(false)
  const linkRef = useRef<HTMLButtonElement>(null)
  const { getAccessTokenSilently, logout } = useAuth0()
  const deleteAccount = async () => {
    const token = await getAccessTokenSilently()

    try {
      const response = await fetch("/api/user", {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.status >= 400) {
        console.error(response)
      } else {
        logout({ returnTo: window.location.origin + "/account-deleted" })
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Wrapper>
      <Header heading="Settings" />
      <Content>
        <Card>
          <CardHeader>
            <CardTitle>Account</CardTitle>
          </CardHeader>
          <CardContent>
            {isEditing ? (
              <>
                <CardBody>
                  Are you sure you want to delete your account? Deleting your
                  account is permanent and will remove all content and settings.
                  This will delete your account on all Pattern family brand
                  sites.
                </CardBody>
                <ColumnsWrapper>
                  <DeleteButton
                    fullWidth
                    onClick={deleteAccount}
                    variant="primary-dark"
                  >
                    Yes, Delete Account
                  </DeleteButton>
                  <Button
                    fullWidth
                    onClick={() => setIsEditing(false)}
                    variant="secondary-dark"
                  >
                    Cancel
                  </Button>
                </ColumnsWrapper>
              </>
            ) : (
              <CardLinkButton ref={linkRef} onClick={() => setIsEditing(true)}>
                Delete Account
              </CardLinkButton>
            )}
          </CardContent>
        </Card>
      </Content>
    </Wrapper>
  )
}
