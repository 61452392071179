import React from "react"
import styled from "styled-components"
import { typography } from "styles"

type LabelProps = {
  children: React.ReactNode
  role?: string
}

export const StyledLabel = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.dark.text?.secondary?.opacityHex};
  margin-bottom: 8px;
  display: block;
`

export const Label = ({ children, ...restProps }: LabelProps) => (
  <StyledLabel {...restProps}>{children}</StyledLabel>
)
