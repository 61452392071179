import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "redux/rootReducer"

import { Tab, Tabs } from "./Tabs"
import { Container, ContentRouter } from "./AccountView.styles"
import { Address } from "./Address"
import { OrderHistory } from "./OrderHistory"
import { OurBrands } from "./OurBrands"
import { Profile } from "./Profile"
import { Settings } from "./Settings"
import { Logout } from "./Logout"
import { ChangePassword } from "./ChangePassword"
import { routes } from "constants/routes"

const tabs: Tab[] = [
  {
    id: 0,
    name: "Profile",
    path: routes.account.profile,
    component: Profile,
  },
  {
    id: 1,
    name: "My Address",
    path: routes.account.address,
    component: Address,
  },
  {
    id: 2,
    name: "Order History",
    path: routes.account.orderHistory,
    component: OrderHistory,
  },
  {
    id: 3,
    name: "Our Brands",
    path: routes.account.ourBrands,
    component: OurBrands,
  },
  {
    id: 4,
    name: "Settings",
    path: routes.account.settings,
    component: Settings,
  },
  {
    id: 5,
    name: "Logout",
    path: routes.account.logout,
    component: Logout,
  },
]

export const AccountsView: React.FC = () => {
  return (
    <Container>
      <Tabs tabs={tabs}>
        <ContentRouter>
          {tabs.map(tab => {
            const Component = tab.component
            return <Component key={tab.name} path={tab.path} />
          })}
        </ContentRouter>
      </Tabs>
      <ContentRouter>
        <ChangePassword path={routes.account.changePassword} />
      </ContentRouter>
    </Container>
  )
}
