import styled from "styled-components"
import { mq } from "styles"

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
  margin-bottom: 40px;
  ${mq.minWidth("md")} {
    max-width: 600px;
    margin-bottom: 48px;
    padding: 0;
  }
  ${mq.minWidth("lg")} {
    align-items: center;
  }
`

export const Heading = styled.h1`
  color: ${({ theme }) => theme.dark.text?.headline?.opacityHex};
  font-size: 32px;

  ${mq.minWidth("sm")} {
    font-size: 36px;
  }

  ${mq.minWidth("md")} {
    font-size: 40px;
  }
`

export const SubHeading = styled.div`
  line-height: 24px;
  margin-top: 8px;

  ${mq.minWidth("sm")} {
    margin-top: 12px;
  }
`
