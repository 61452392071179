import { User } from "@auth0/auth0-react"

// Example sub values per login type
// google: google-oauth2|110311548005702140540
// facebook: facebook|10216460909594177
// email: auth0|62b366c5f251f66851605909

export const loggedWithEmail = (user: User) => {
  return user && user.sub.startsWith("auth0|")
}
