import React from "react"

import { HeaderWrapper, Heading, SubHeading } from "./Header.styles"

type HeaderProps = {
  heading: string
  name?: string
}

export const Header: React.FC<HeaderProps> = ({ heading, name, children }) => (
  <HeaderWrapper>
    <Heading>{heading}</Heading>
    {name ||
      (children && (
        <SubHeading>
          {name && `Hi ${name}. `}
          {children}
        </SubHeading>
      ))}
  </HeaderWrapper>
)
