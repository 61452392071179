import { object, string } from "yup"

export const addressSchema = object({
  address1: string().required("Required"),
  address2: string(),
  city: string().required("Required"),
  zip: string()
    .required("Required")
    .matches(/^\d{5}(?:[- ]?\d{4})?$/, "Must be valid zip code"),
  provinceCode: string()
    .required("Required")
    .matches(/^[A-Z]{2}$/, "Must be valid province code"),
  countryCode: string()
    .required("Required")
    .matches(/^[A-Z]{2}$/, "Must be valid country code")
    .default("US"),
})
