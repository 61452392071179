import React, { useState, useCallback, useRef, useEffect } from "react"
import { Label } from "components/UI/Label"
import { useClickOutside } from "hooks/useClickOutside"
import { useKeyPress } from "react-use"
import { positions, UIRounding } from "./dropdown.positions"
import { DropdownPosition } from "typings/modules"
import { Container, MenuList } from "components/UI/Dropdown/Dropdown.styles"
import { DropdownButton } from "components/UI/Dropdown/DropdownButton"
import { useTheme } from "styled-components"

type Props = {
  label?: string
  renderList: React.ReactNode
  renderButton: React.ReactNode
  onClick?: (isOpen: boolean) => void
  position?: DropdownPosition
  forceClose?: boolean
}

export const Dropdown = ({
  renderButton,
  renderList,
  label,
  onClick,
  position = "bottom left",
  forceClose,
}: Props) => {
  const escPressed = useKeyPress("Escape")
  const ref = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const closeDropdown = useCallback(() => setIsOpen(false), [setIsOpen])
  const theme = useTheme()
  const positionStyles = positions
    .find(p => p.position === position)
    ?.getStyles(theme.global.uiRounding as UIRounding)
  const handleClick = useCallback(() => {
    setIsOpen(!isOpen)
    if (typeof onClick === "function") {
      onClick(isOpen)
    }
  }, [isOpen, onClick])
  const onEscPress = useCallback(() => {
    if (escPressed) {
      closeDropdown()
    }
  }, [escPressed, closeDropdown])
  useClickOutside(ref, closeDropdown)
  useEffect(onEscPress, [onEscPress])
  const closeOnForceClose = useCallback(() => {
    if (isOpen && forceClose) closeDropdown()
  }, [isOpen, forceClose, closeDropdown])
  useEffect(closeOnForceClose, [closeOnForceClose])
  return (
    <>
      {label && <Label>{label}</Label>}
      <Container ref={ref}>
        <DropdownButton
          onClick={handleClick}
          button={renderButton}
          isOpen={isOpen}
        />
        {isOpen && (
          <MenuList onClick={closeDropdown} style={positionStyles}>
            {renderList}
          </MenuList>
        )}
      </Container>
    </>
  )
}
