import React from "react"
import {
  Current,
  CurrentLeft,
  StyledArrow,
  MenuButton,
} from "components/UI/Dropdown/DropdownButton.styles"

type DropdownButtonProps = {
  onClick: () => void
  button: React.ReactNode
  isOpen: boolean
}

export const DropdownButton = ({
  onClick,
  button,
  isOpen,
}: DropdownButtonProps) => {
  return (
    <MenuButton type="button" aria-pressed={isOpen} onClick={onClick}>
      <Current>
        <CurrentLeft>{button}</CurrentLeft>
        <StyledArrow
          role="img"
          aria-label="Dropdown Icon"
          $isFlipped={isOpen}
        />
      </Current>
    </MenuButton>
  )
}
