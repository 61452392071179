import React from "react"
import { Content, Wrapper } from "./AccountView.styles"
import {
  Card,
  CardButton,
  CardContent,
  CardHeader,
  CardTitle,
} from "./Card.styles"
import { Header } from "./Header"
import { ChangePasswordForm } from "./ChangePasswordForm"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { showNotification } from "redux/notification"
import { routes } from "constants/routes"

export const ChangePassword: React.FC<{ path?: string }> = () => {
  const dispatch = useDispatch()

  const handleSuccess = () => {
    dispatch(
      showNotification({
        message: "Password updated successfully",
        autoHide: true,
        theme: "success",
      })
    )
    navigate(routes.account.profile)
  }
  return (
    <Wrapper>
      <Header heading="Change Password"></Header>
      <Content>
        <Card>
          <CardHeader>
            <CardTitle>Set new password</CardTitle>
            <CardButton
              onClick={() => {
                navigate(routes.account.profile)
              }}
            >
              Cancel
            </CardButton>
          </CardHeader>
          <CardContent>
            <ChangePasswordForm onSuccess={handleSuccess} />
          </CardContent>
        </Card>
      </Content>
    </Wrapper>
  )
}
