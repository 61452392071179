import React from "react"
import { Content, Wrapper } from "./AccountView.styles"
import {
  BrandCard,
  BrandImage,
  BrandTitle,
  BrandButton,
  BrandContent,
} from "./OurBrands.styles"
import { Header } from "./Header"

import PatternLogo from "../../assets/svg/pattern-logo.svg"
import OpenSpacesLogo from "../../assets/svg/open-spaces-logo.svg"
import LetterfolkLogo from "../../assets/svg/letterfolk-logo.svg"
import GirLogo from "../../assets/svg/gir-logo.svg"
import EqualPartsLogo from "../../assets/svg/equal-parts-logo.svg"
import YieldLogo from "../../assets/svg/yield-logo.svg"
import PoketoLogo from "../../assets/svg/poketo-logo.svg"
import { navigate } from "gatsby"
import { Card } from "components/Account/Card.styles"

const ourBrands = [
  {
    logo: PatternLogo,
    title: "Pattern",
    copy: "Design-forward goods for everyday living.",
    link: "https://www.patternbrands.com/",
  },
  {
    logo: OpenSpacesLogo,
    title: "Open Spaces",
    copy: "Organization essentials designed to work beautifully in any room.",
    link: "https://www.getopenspaces.com/",
  },
  {
    logo: LetterfolkLogo,
    title: "Letterfolk",
    copy: "Creative home decor to personalize your space.",
    link: "https://www.letterfolk.com/",
  },
  {
    logo: GirLogo,
    title: "GIR",
    copy:
      "Brilliantly engineered kitchen tool for everyday use. We Get It Right.",
    link: "https://www.gir.co/",
  },
  {
    logo: YieldLogo,
    title: "Yield",
    copy: "New American standards.",
    link: "https://yielddesign.co/",
  },
  {
    logo: PoketoLogo,
    title: "Poketo",
    copy: "Elevated Design Made With Responsibly Crafted Materials.",
    link: "https://www.poketo.com/",
  },
  {
    logo: EqualPartsLogo,
    title: "Equal Parts",
    copy:
      "Easy-to-use, fun-to-use cookware for creativity and play in the kitchen.",
    link: "https://www.patternbrands.com/shop/equal-parts/",
  },
]

export const OurBrands: React.FC<{ path?: string }> = () => {
  return (
    <Wrapper>
      <Header heading="Our Brands">
        Explore the Pattern family of brands below.
      </Header>
      <Content>
        {ourBrands.map(({ logo, title, copy, link }) => {
          const Logo = logo
          return (
            <Card key={title}>
              <BrandCard>
                <BrandImage>
                  <Logo />
                </BrandImage>
                <BrandTitle>{title}</BrandTitle>
                <BrandButton onClick={() => navigate(link)}>Shop</BrandButton>
                <BrandContent>{copy}</BrandContent>
              </BrandCard>
            </Card>
          )
        })}
      </Content>
    </Wrapper>
  )
}
