import React from "react"
import { Field } from "formik"
import styled from "styled-components"
import { mq } from "styles"
import ErrorIconSVG from "assets/svg/error-icon.svg"

export const InputContainer = styled.div<{ $hasError?: boolean }>`
  display: flex;
  position: relative;
  flex: 1;
  height: 64px;
  color: var(--text_dark_primary);
  margin-bottom: 16px;
  font-size: 15px;
  width: 100%;

  ${mq.minWidth("sm")} {
    margin-bottom: 24px;
    font-size: 16px;
  }

  border: ${({ $hasError }) =>
    $hasError ? "1px solid var(--form-error)" : "1px solid transparent"};
`

export const InputLabel = styled.label<{ active?: boolean }>`
  position: absolute;
  pointer-events: auto;
  width: 100%;
  font-size: inherit;
  top: 24px;
  left: 20px;
  color: ${({ theme }) => theme.dark.text?.secondary?.opacityHex};
  z-index: 1;
  transition: all 0.3s ease-in-out;
`

export const InputField = styled(Field)`
  position: absolute;
  appearance: none;
  width: 100%;
  height: 64px;
  background: rgba(102, 54, 10, 0.06);
  padding: 26px 20px 14px;
  font-size: inherit;
  border: none;
  outline: none;
  z-index: 2;
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};

  &:focus
    + ${InputLabel},
    &:not(:placeholder-shown)&:not(:focus)
    + ${InputLabel} {
    font-size: 10px;
    transform: translate3d(0, -20px, 0);
  }
`

export const ColumnsWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  ${mq.minWidth("md")} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
`

export const Column = styled.div`
  flex: 1;
`

export const ErrorContainer = styled.div`
  margin-top: -6px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 6px;

  ${mq.minWidth("sm")} {
    margin-top: -14px;
  }
`

export const ErrorMessage = styled.p`
  font-size: 12px;
  color: var(--form-error);
  margin: 0;
`

export const ErrorIcon = () => <ErrorIconSVG />
