import { object, string } from "yup"

export const userSchema = object({
  firstName: string().required("Required"),
  lastName: string().required("Required"),
})

export const emailSchema = object({
  email: string().email().required("Required"),
})
