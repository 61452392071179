import { DropdownItem } from "components/UI/Dropdown/Dropdown.styles"
import {
  MenuButton,
  CurrentLeft,
} from "components/UI/Dropdown/DropdownButton.styles"
import styled, { css } from "styled-components"
import { mq } from "styles"

export const AddressDropdown = styled.div<{
  $hasError?: boolean
  $hasValue?: boolean
}>`
  position: relative;
  margin-bottom: 16px;

  ${mq.minWidth("sm")} {
    margin-bottom: 24px;
  }

  ${MenuButton} {
    background: rgba(102, 54, 10, 0.06);
    border: ${({ $hasError }) =>
      $hasError ? "1px solid var(--form-error)" : "1px solid transparent"};
    height: 64px;
  }

  ${CurrentLeft} {
    font-size: 15px;
    color: ${({ theme, $hasValue }) =>
      $hasValue
        ? theme.dark.text?.primary?.opacityHex
        : theme.dark.text?.secondary?.opacityHex};

    ${({ $hasValue }) =>
      $hasValue &&
      css`
        padding-top: 11px;
        padding-left: 4px;
      `}

    ${mq.minWidth("sm")} {
      font-size: 16px;
    }
  }

  ${({ $hasValue }) =>
    $hasValue &&
    css`
      &:after {
        content: "State";
        position: absolute;
        color: ${({ theme }) => theme.dark.text?.secondary?.opacityHex};
        font-size: 10px;
        top: 4px;
        left: 20px;
      }
    `}
`

export const AddressDropdownList = styled.div`
  height: 200px;
  overflow: auto;
  display: grid;
  background: none;
`
export const AddressDropdownItem = styled(DropdownItem)`
  height: 48px;
  width: 100%;
  background: none;
  color: var(--text_dark_primary);
`
