import { object, string, ref, TypeOf } from "yup"

// Password is too weak. Make sure it contains:
// * At least 8 characters in length
// * Contain at least 3 of the following 4 types of characters:
//  * lower case letters (a-z)
//  * upper case letters (A-Z)
//  * numbers (i.e. 0-9)
//  * special characters (e.g. !@#$%^&*)

export const passwordSchema = object({
  newPassword: string()
    .required("Password is required")
    .min(8, "Must contain at least 8 characters")
    .test(
      "is-strong-password",
      "Must Contain at least 3 of the following 4 types of characters: * lower case letters (a-z), * upper case letters (A-Z), * numbers (i.e. 0-9), * special characters (e.g. !@#$%^&*)",
      value => {
        const includesLowerCaseLetters = /[a-z]/.test(value)
        const includesUpperCaseLetters = /[A-Z]/.test(value)
        const includesNumbers = /[0-9]/.test(value)
        const includesSpecialCharacters = /[^\w\s]/.test(value)

        let strength = 0
        if (includesLowerCaseLetters) {
          strength += 1
        }
        if (includesUpperCaseLetters) {
          strength += 1
        }
        if (includesNumbers) {
          strength += 1
        }
        if (includesSpecialCharacters) {
          strength += 1
        }

        if (strength < 3) {
          return false
        }

        return true
      }
    ),
  confirmNewPassword: string()
    .oneOf([ref("newPassword"), null], "Passwords must match")
    .required(),
})

export type passwordFields = TypeOf<typeof passwordSchema>
