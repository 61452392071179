import React, { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"

export const Logout: React.FC<{ path?: string }> = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({ returnTo: window.location.origin })
  }, [])

  return null
}
