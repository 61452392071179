import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "components/UI/Button/Button"
import { Form, Formik } from "formik"
import React, { useState } from "react"
import styled from "styled-components"
import { typography } from "styles"
import {
  passwordFields,
  passwordSchema,
} from "validationSchemas/passwordSchema"
import { TextInput } from "./TextInput"

export const ErrorMessage = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.dark?.text?.formError?.opacityHex};
  padding: 10px;
`

type ChangePasswordProps = {
  onSuccess: () => void
}

export const ChangePasswordForm: React.FC<ChangePasswordProps> = ({
  onSuccess,
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const [errorMessage, setErrorMessage] = useState("")
  const initialValues: passwordFields = {
    newPassword: "",
    confirmNewPassword: "",
  }

  const updateUser = async (data: passwordFields) => {
    const token = await getAccessTokenSilently()

    const response = await fetch("/api/user/password", {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

    const responseJson = await response.json()

    if (response.status >= 400) {
      setErrorMessage(
        responseJson.message ||
          "Could not update user password. Please try again later."
      )
    } else {
      onSuccess()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={passwordSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async updatedPasswordFields => {
        try {
          setErrorMessage("")
          await updateUser(updatedPasswordFields)
        } catch (error) {
          setErrorMessage(
            "Could not update user password. Please try again later."
          )
        }
      }}
    >
      {({
        dirty,
        handleChange,
        handleBlur,
        isSubmitting,
        values,
        touched,
        errors,
        isValid,
      }) => (
        <Form>
          <TextInput
            id="newPassword"
            name="newPassword"
            type="password"
            value={values.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.newPassword}
            touched={touched.newPassword}
            placeholder="New Password"
            maxLength={255}
          />
          <TextInput
            id="confirmNewPassword"
            name="confirmNewPassword"
            type="password"
            value={values.confirmNewPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.confirmNewPassword}
            touched={touched.confirmNewPassword}
            placeholder="Confirm New Password"
            maxLength={255}
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Button
            disabled={isSubmitting || !dirty || !isValid}
            type="submit"
            fullWidth
          >
            {isSubmitting ? "Saving..." : "Save Changes"}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
