import styled from "styled-components"
import { typography, animations, zIndexes } from "styles"
import { NoStyleButton } from "../Button/NoStyleButton"

const ITEM_HEIGHT = 48

export const Container = styled.div`
  min-width: 180px;
  position: relative;
`

export const MenuList = styled.div`
  position: absolute;
  z-index: ${zIndexes.zIndex7};
  background: ${({ theme }) => theme.global.dropdown.fill?.opacityHex};
  border: 1px solid ${({ theme }) => theme.global.dropdown?.stroke?.opacityHex};
  border-radius: var(--ui-truncated-rounding);
  min-width: 100%;
  &:focus {
    outline: 0;
  }
  max-height: ${ITEM_HEIGHT * 4.5}px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--slider-bar-active);
    border-radius: 3px;
  }
`

export const DropdownItem = styled(NoStyleButton)`
  ${typography.bodySmall};
  height: ${ITEM_HEIGHT}px;
  color: ${({ theme }) => theme.global.dropdown?.primaryText?.opacityHex};
  background-color: ${({ theme }) =>
    theme.global.dropdown?.fill?.opacityHex || "auto"};
  transition: 300ms background ${animations.defaultBezier};
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  width: 100%;
  padding: 0 16px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: var(--ui-truncated-rounding);
  &:hover {
    background: ${({ theme }) => theme.global.dropdown?.hoverFill?.opacityHex};
  }
  &:focus-visible {
    outline: 0;
    border: 2px solid var(--tab-stroke);
  }
`
