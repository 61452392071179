import React, { ChangeEvent } from "react"
import {
  ErrorContainer,
  ErrorIcon,
  ErrorMessage,
  InputContainer,
  InputField,
  InputLabel,
} from "./Form.styles"

type TextInputProps = {
  id: string
  maxLength?: number
  placeholder: string
  name: string
  onBlur?: (e: ChangeEvent) => void
  onChange?: (e: ChangeEvent) => void
  value?: string
  error?: string
  touched: boolean
  type?: string
}

export const TextInput: React.FC<TextInputProps> = ({
  id,
  maxLength,
  placeholder,
  name,
  onBlur,
  onChange,
  value,
  error,
  touched,
  type,
}) => {
  return (
    <>
      <InputContainer key={id} $hasError={error && touched}>
        <InputField
          id={id}
          type={type || "text"}
          name={id}
          maxLength={maxLength}
          placeholder=" "
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
        <InputLabel>{placeholder ?? name}</InputLabel>
      </InputContainer>
      {error && touched ? (
        <ErrorContainer>
          <ErrorIcon />
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorContainer>
      ) : null}
    </>
  )
}
