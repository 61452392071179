import {
  CardButton,
  CardContent,
  CardTitle,
} from "components/Account/Card.styles"
import styled from "styled-components"
import { mq } from "styles"

export const BrandCard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 64px 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "image title title title title title title button"
    "content content content content content content content content";
  ${mq.minWidth("md")} {
    grid-template-areas:
      "image title title title title title title button"
      "image content content content content content content button";
  }
`

export const BrandImage = styled.div`
  grid-area: image;
  width: 64px;
  height: 50px;
  display: grid;
  align-self: center;
  align-items: center;
  svg {
    display: block;
    margin: 0 auto;
  }
`

export const BrandTitle = styled(CardTitle)`
  grid-area: title;
  align-self: center;
  padding-left: 16px;
  ${mq.minWidth("md")} {
    justify-self: flex-end;
    padding-left: 24px;
    width: 100%;
  }
`

export const BrandButton = styled(CardButton)`
  grid-area: button;
  align-self: center;
  justify-self: flex-end;
`

export const BrandContent = styled(CardContent)`
  grid-area: content;
  margin-top: 12px;
  ${mq.minWidth("md")} {
    width: 100%;
    margin-top: 6px;
    padding-left: 24px;
    padding-right: 24px;
  }
`
