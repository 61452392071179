import { useAuth0 } from "@auth0/auth0-react"
import { Config } from "config/brands"
import React, { useEffect, useState } from "react"
import { Content, NoDataImage, Wrapper } from "./AccountView.styles"
import { AddressForm } from "./AddressForm"
import {
  Card,
  CardButton,
  CardContent,
  CardHeader,
  CardTitle,
  UserData,
} from "./Card.styles"
import { Header } from "./Header"
import NoAddress from "assets/images/no-address.png"
import { Button } from "components/UI/Button/Button"

const addressKey = Config.auth0.metaDataNamespace + "address"

export const Address: React.FC<{ path?: string }> = () => {
  const [address, setAddress] = useState<AccountAddress>()
  const [isEditing, setIsEditing] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const { user, getAccessTokenSilently } = useAuth0()
  const storedAddress = user?.[addressKey]

  const initialAddress = {
    address1: "",
    address2: "",
    city: "",
    provinceCode: "",
    zip: "",
    countryCode: "US",
    phone: "",
  }
  const { address1, address2, city, provinceCode, zip } =
    address || initialAddress

  useEffect(() => setAddress(storedAddress || initialAddress), [storedAddress])

  const updateAddress = async (address: AccountAddress) => {
    const token = await getAccessTokenSilently()

    const response = await fetch("/api/user/address", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...address }),
    })

    if (response.status >= 400) {
      console.error(response)

      throw new Error("Error saving address")
    } else {
      user[addressKey] = address

      // We need this so the locally cached idToken is updated.
      // The idToken contains the user data including the address.
      // If we don't update the idToken, then the address changes will be lost on page refresh

      await getAccessTokenSilently({
        ignoreCache: true,
      })

      setIsEditing(false)
      setIsAdding(false)
    }
  }

  const hasAddress = storedAddress

  return (
    <Wrapper>
      <Header heading="My Address">
        {hasAddress
          ? "Edit your contact details here."
          : isAdding
          ? "Please fill in your shipping address information below."
          : "Looks like you don't have an address yet."}
      </Header>
      <Content>
        {hasAddress ? (
          <Card>
            <CardHeader>
              <CardTitle>Shipping Address</CardTitle>
              <CardButton onClick={() => setIsEditing(!isEditing)}>
                {isEditing ? "Cancel" : "Edit"}
              </CardButton>
            </CardHeader>
            <CardContent>
              {isEditing ? (
                <AddressForm values={address} onSubmit={updateAddress} />
              ) : (
                <div>
                  <UserData>{address1}</UserData>
                  <UserData>{address2}</UserData>
                  <UserData>
                    {city}, {provinceCode}
                  </UserData>
                  <UserData>{zip}</UserData>
                </div>
              )}
            </CardContent>
          </Card>
        ) : (
          <>
            {isAdding ? (
              <Card>
                <CardHeader>
                  <CardTitle>Shipping Address</CardTitle>
                  <CardButton onClick={() => setIsAdding(false)}>
                    Cancel
                  </CardButton>
                </CardHeader>
                <CardContent>
                  <AddressForm values={address} onSubmit={updateAddress} />
                </CardContent>
              </Card>
            ) : (
              <>
                <NoDataImage src={NoAddress} alt="No address image" />
                <Button onClick={() => setIsAdding(true)}>Add Address</Button>
              </>
            )}
          </>
        )}
      </Content>
    </Wrapper>
  )
}
