import { useAuth0 } from "@auth0/auth0-react"
import {
  ItemTitle,
  StyledFigcaption,
  TextContainer,
  Image,
  PriceDiscountArea,
  PriceArea,
  CompareAtPrice,
  Price,
  DiscountArea,
  PriceTag,
} from "components/Cart/LineItem.styles"
import { Button } from "components/UI/Button/Button"
import React, { useEffect, useState } from "react"
import { humanizeDate } from "utils/dateUtils"
import { usdFmt } from "utils/priceUtils"
import { Content, NoDataImage, Wrapper } from "./AccountView.styles"
import { Card, CardButton, CardTitle, AddressHeading } from "./Card.styles"
import { Column } from "./Form.styles"
import { Header } from "./Header"
import LoadingIcon from "assets/svg/loading-icon.svg"
import NoOrders from "assets/images/no-orders.png"
import {
  OrderItem,
  OrderBody,
  OrderMeta,
  OrderMiddleContainer,
  OrderOverview,
  OrderList,
  OrderTotalsContainer,
  OrderImageContainer,
  OrderSubtotal,
  OrderTotal,
  OrderAddressItem,
  OrderColumnsWrapper,
  OrderTrackWrapper,
  OrderStatusText,
  OrderTrackContact,
} from "components/Account/OrderHistory.styles"
import { BasicLink } from "components/UI/BasicLink/BasicLink"
import { capitalizeFirstLetter } from "utils/stringUtils"

type OrderItemType = {
  id: string
  name: string
  variantTitle: string
  image: {
    id: string
    url: string
  }
  discountedTotalSet: {
    shopMoney: {
      amount: string
    }
  }
  discountAllocations: {
    allocatedAmountSet: {
      shopMoney: {
        amount: string
      }
    }
  }[]
}

type OrderDetails = {
  discountCode: string | null
  cartDiscountAmountSet: {
    shopMoney: {
      amount: string
    }
  } | null
  lineItems: {
    nodes: OrderItemType[]
  }
  variantTitle: string
  orderUrl: string
  createdAt: string
  fulfillments: [
    {
      trackingInfo: [
        {
          url: string
        }
      ]
    }
  ]
  shippingAddress: {
    address1: string
    address2: string
    city: string
    firstName: string
    lastName: string
    provinceCode: string
    zip: string
  }
  billingAddress: {
    address1: string
    address2: string
    city: string
    firstName: string
    lastName: string
    provinceCode: string
    zip: string
  }
  subtotalPriceSet: {
    shopMoney: {
      amount: string
    }
  }
  totalTaxSet: {
    shopMoney: {
      amount: string
    }
  }
  totalPriceSet: {
    shopMoney: {
      amount: string
    }
  }
  totalShippingPriceSet: {
    shopMoney: {
      amount: string
    }
  }
}

type Order = {
  id: string
  createdAt: string
  name: string
  fulfillments: [
    {
      displayStatus: string
    }
  ]
}

type PageInfo = {
  hasNextPage: boolean
  endCursor: string
}

type AccordionCardProps = {
  order: Order
}

const AccordionCard: React.FC<AccordionCardProps> = ({ order }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [orderDetails, setOrderDetails] = useState<OrderDetails>()
  const { getAccessTokenSilently } = useAuth0()

  const formatPrice = (price: string) =>
    parseFloat(price) ? usdFmt(price, true) : "Free"

  const getOrderById = async (orderId: string) => {
    const token = await getAccessTokenSilently()

    try {
      const response = await fetch(`/api/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const { order } = await response.json()
      setOrderDetails(order)
    } catch (err) {
      console.error(err)
    }
  }
  const fetchOrderDetails = async (orderId: string) => {
    if (!orderDetails) {
      const numericId = orderId.split("/").pop()
      await getOrderById(numericId)
    }
    setIsExpanded(!isExpanded)
  }

  const trackingUrl = orderDetails?.fulfillments[0]?.trackingInfo[0]?.url
  const orderStatus =
    (order.fulfillments?.length && order.fulfillments[0]?.displayStatus) ||
    "Submitted"
  const discountValue = orderDetails?.cartDiscountAmountSet
    ? parseFloat(orderDetails.cartDiscountAmountSet.shopMoney.amount)
    : 0

  const renderPrice = (lineItem: OrderItemType) => {
    const preDiscountPrice = lineItem.discountedTotalSet.shopMoney.amount
    const preDiscountPriceFmt = formatPrice(preDiscountPrice)
    const displayedPrice = discountValue
      ? (parseFloat(preDiscountPrice) - discountValue).toString()
      : preDiscountPrice
    const displayedPriceFmt = formatPrice(displayedPrice)
    return (
      <PriceDiscountArea>
        <PriceArea>
          {discountValue ? (
            <CompareAtPrice>{preDiscountPriceFmt}</CompareAtPrice>
          ) : null}
          <Price data-test="Line Item Price">{displayedPriceFmt}</Price>
        </PriceArea>
        {!!discountValue && (
          <DiscountArea>
            <PriceTag role="img" aria-label="Price Tag Icon" />
            {orderDetails.discountCode}
          </DiscountArea>
        )}
      </PriceDiscountArea>
    )
  }

  return (
    <Card>
      <OrderOverview>
        <OrderMeta>
          <CardTitle>{order.name}</CardTitle>
          <OrderBody>{humanizeDate(order.createdAt)}</OrderBody>
          <OrderBody>
            Status:{" "}
            <OrderStatusText $status={capitalizeFirstLetter(orderStatus)}>
              {capitalizeFirstLetter(orderStatus)}
            </OrderStatusText>
          </OrderBody>
        </OrderMeta>
        <CardButton onClick={() => fetchOrderDetails(order.id)}>
          {isExpanded ? "Close" : "View"}
        </CardButton>
      </OrderOverview>
      {isExpanded ? (
        <OrderList>
          {orderDetails &&
            orderDetails?.lineItems?.nodes?.map(item => (
              <OrderItem role="listitem" key={item.id}>
                <OrderImageContainer>
                  <Image src={item.image?.url} />
                </OrderImageContainer>
                <OrderMiddleContainer>
                  <TextContainer>
                    <div>
                      <ItemTitle>{item.name}</ItemTitle>
                      {item.variantTitle && (
                        <StyledFigcaption role="article">
                          {item.variantTitle}
                        </StyledFigcaption>
                      )}
                    </div>
                  </TextContainer>
                  <StyledFigcaption data-test="Line Item Price">
                    {renderPrice(item)}
                  </StyledFigcaption>
                </OrderMiddleContainer>
              </OrderItem>
            ))}
          <OrderTotalsContainer role="list" aria-label="Your Totals">
            <OrderSubtotal role="listitem">
              <span>Subtotal</span>
              {formatPrice(orderDetails?.subtotalPriceSet.shopMoney.amount)}
            </OrderSubtotal>
            {discountValue ? (
              <OrderSubtotal role="listitem">
                <span>Discount: "{orderDetails?.discountCode}"</span>
                {"-"}
                {formatPrice(
                  orderDetails?.cartDiscountAmountSet.shopMoney.amount
                )}
              </OrderSubtotal>
            ) : null}
            <OrderSubtotal role="listitem">
              <span>Shipping</span>
              <div data-test="Shipping Amount">
                {formatPrice(
                  orderDetails?.totalShippingPriceSet.shopMoney.amount
                )}
              </div>
            </OrderSubtotal>
            <OrderSubtotal role="listitem">
              <span>Taxes</span>
              {formatPrice(orderDetails?.totalTaxSet.shopMoney.amount)}
            </OrderSubtotal>
            <OrderTotal role="listitem">
              <span>Total</span>
              <span>
                {formatPrice(orderDetails?.totalPriceSet.shopMoney.amount)}
              </span>
            </OrderTotal>
          </OrderTotalsContainer>
          <OrderColumnsWrapper>
            <Column>
              <AddressHeading>Shipping Address</AddressHeading>
              <OrderAddressItem>
                {orderDetails?.shippingAddress.firstName}{" "}
                {orderDetails?.shippingAddress.lastName}
              </OrderAddressItem>
              <OrderAddressItem>
                {orderDetails?.shippingAddress.address1}
              </OrderAddressItem>
              <OrderAddressItem>
                {orderDetails?.shippingAddress.address2}
              </OrderAddressItem>
              <OrderAddressItem>
                {orderDetails?.shippingAddress.city}
                {", "}
                {orderDetails?.shippingAddress.provinceCode}{" "}
                {orderDetails?.shippingAddress.zip}
              </OrderAddressItem>
            </Column>
            <Column>
              <AddressHeading>Billing Address</AddressHeading>
              <OrderAddressItem>
                {orderDetails?.billingAddress.firstName}{" "}
                {orderDetails?.billingAddress.lastName}
              </OrderAddressItem>
              <OrderAddressItem>
                {orderDetails?.billingAddress.address1}
              </OrderAddressItem>
              <OrderAddressItem>
                {orderDetails?.billingAddress.address2}
              </OrderAddressItem>
              <OrderAddressItem>
                {orderDetails?.billingAddress.city}
                {", "}
                {orderDetails?.billingAddress.provinceCode}{" "}
                {orderDetails?.billingAddress.zip}
              </OrderAddressItem>
            </Column>
          </OrderColumnsWrapper>
          <OrderTrackWrapper>
            <div>
              <Button to={trackingUrl} newTab disabled={!trackingUrl}>
                {trackingUrl ? "Track Order" : "Tracking Not Available"}
              </Button>
            </div>
            <OrderTrackContact>
              <BasicLink to="mailto:help@patternbrands.com">
                Contact us about this order
              </BasicLink>
            </OrderTrackContact>
          </OrderTrackWrapper>
        </OrderList>
      ) : null}
    </Card>
  )
}

export const OrderHistory: React.FC<{ path?: string }> = () => {
  const [orders, setOrders] = useState<Order[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>()
  const [loading, setLoading] = useState(true)
  const { getAccessTokenSilently } = useAuth0()

  const urlEncodeCursor = (cursor: string) => {
    return cursor.replace("=", "-").replace("+", ".").replace("/", "_")
  }

  const getOrders = async (cursor?: string) => {
    const token = await getAccessTokenSilently()

    try {
      const response = await fetch(
        `/api/orders${cursor ? `/?cursor=${urlEncodeCursor(cursor)}` : ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      setLoading(false)

      if (response.status >= 400) {
        console.error(response)
      } else {
        const ordersResponse = await response.json()
        setOrders([...orders, ...(ordersResponse?.orders || [])])
        setPageInfo(ordersResponse.pageInfo)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getOrders()
  }, [])

  return (
    <Wrapper>
      <Header heading="Order History">
        {loading
          ? ""
          : !orders.length
          ? `Looks like you don't have any orders yet.`
          : "Check the status of your orders here."}
      </Header>
      <Content>
        {loading ? (
          <LoadingIcon />
        ) : orders?.length ? (
          orders.map((order: Order) => (
            <AccordionCard key={order.id} order={order} />
          ))
        ) : (
          <>
            <NoDataImage src={NoOrders} alt="No orders image" />
            <Button to="/shop">Shop Now</Button>
          </>
        )}
        {pageInfo?.hasNextPage && (
          <Button onClick={() => getOrders(pageInfo.endCursor)}>
            Load More
          </Button>
        )}
      </Content>
    </Wrapper>
  )
}
