import React, { FC } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { graphql } from "gatsby"
import { setTheme } from "helpers/Theme"
import { Query } from "typings/graphql"
import { App } from "components/App/App"
import { AccountsView } from "components/Account/AccountView"
import { PageLoadingSpinner } from "components/UI/Spinner"

type PageProps = {
  data: Query
}

const AuthMessage = () => {
  const { isAuthenticated, isLoading } = useAuth0()

  return isLoading ? (
    <PageLoadingSpinner />
  ) : isAuthenticated ? (
    <AccountsView />
  ) : (
    <PageLoadingSpinner />
  )
}

const AccountPage: FC<PageProps> = ({ data }) => {
  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )

  return (
    <App theme={theme} layout="page" mode={"dark"}>
      <AuthMessage />
    </App>
  )
}

export const pageQuery = graphql`
  query AccountPage {
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
  }
`

export default AccountPage
