import { Link } from "gatsby"
import styled from "styled-components"
import { mq, typography } from "styles"

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  font-family: ${({ theme }) => theme.typography?.body1?.typeface?.fontName};
`

export const TabsNav = styled.ul`
  display: flex;
  width: 100%;
  column-gap: 24px;
  font-size: 18px;
  line-height: 24px;
  overflow-x: scroll;
  padding: 0 30px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${mq.minWidth("md")} {
    column-gap: 40px;
    font-size: 20px;
    line-height: 26px;
    justify-content: center;
  }
`

export const TabsNavItem = styled.li`
  flex-shrink: 0;
`

export const TabsNavLink = styled(Link)`
  ${typography.defaultLink};
  color: ${({ theme }) => theme.dark.text?.linksText?.opacityHex};
  border-bottom: none;

  &[data-active] {
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.dark?.text?.primary?.opacityHex}`};
  }
`
