import { NewsletterSignup } from "components/Footer/NewsletterSignup"
import { Input, Label } from "components/Footer/NewsletterSignup.styles"
import styled from "styled-components"
import { mq } from "styles"
import { Router } from "@reach/router"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-width: 360px;
  width: 100%;
  min-height: 100vh;
  padding: 32px 0px 64px 0px;
  background-color: ${({ theme }) =>
    theme?.dark?.canvas?.secondary?.opacityHex};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};

  ${mq.minWidth("sm")} {
    padding: 48px 0 80px 0;
  }

  ${mq.minWidth("lg")} {
    padding: 48px 0 96px 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
  width: 100%;
  ${mq.minWidth("sm")} {
    max-width: 576px;
    margin-top: 64px;
  }
  ${mq.minWidth("md")} {
    max-width: 600px;
    margin-top: 64px;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContentRouter = styled(Router)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledNewsletter = styled(NewsletterSignup)`
  ${Label} {
    color: ${({ theme }) => theme.dark.text?.secondary?.opacityHex};
  }

  ${Input} {
    color: ${({ theme }) => theme.dark.text?.secondary?.opacityHex};
  }
`

export const NoDataImage = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 24px;

  ${mq.minWidth("sm")} {
    width: 240px;
    height: 240px;
    margin-bottom: 32px;
  }
`
