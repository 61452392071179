import { Button } from "components/UI/Button/Button"
import { Form, Formik } from "formik"
import React, { useState } from "react"
import styled from "styled-components"
import { typography } from "styles"
import { addressSchema } from "validationSchemas/addressSchema"
import { Column, ColumnsWrapper } from "./Form.styles"
import { ProvinceCodeSelect } from "./ProvinceCodeSelect"
import { TextInput } from "./TextInput"

const stateCodes = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
]

export const ErrorMessage = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.dark?.text?.formError?.opacityHex};
  padding: 10px;
`

type AddressFormProps = {
  values: AccountAddress
  onSubmit: (address: AccountAddress) => Promise<void>
}

export const AddressForm: React.FC<AddressFormProps> = ({
  values,
  onSubmit,
}) => {
  const [errorMessage, setErrorMessage] = useState("")
  const hasAddress = Object.values(values).some(Boolean)
  return (
    <Formik
      initialValues={values}
      validationSchema={addressSchema}
      onSubmit={async formAddress => {
        try {
          setErrorMessage("")
          await onSubmit(formAddress)
        } catch (error) {
          setErrorMessage("Could not save address. Please try again later.")
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        isSubmitting,
        dirty,
        errors,
        touched,
        setFieldValue,
      }) => (
        <Form>
          <TextInput
            id="address1"
            maxLength={255}
            name="address1"
            placeholder="Address 1"
            value={values.address1}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.address1}
            touched={touched.address1}
          />
          <TextInput
            id="address2"
            maxLength={255}
            name="address2"
            placeholder="Address 2 (Optional)"
            value={values.address2}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.address2}
            touched={touched.address2}
          />
          <ColumnsWrapper>
            <Column>
              <TextInput
                id="city"
                maxLength={255}
                name="city"
                placeholder="City"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.city}
                touched={touched.city}
              />
            </Column>
            <Column>
              <ProvinceCodeSelect
                error={errors.provinceCode}
                touched={touched.provinceCode}
                value={values.provinceCode}
                placeholder="State"
                options={stateCodes}
                onChange={value => setFieldValue("provinceCode", value)}
              />
            </Column>
          </ColumnsWrapper>
          <TextInput
            id="zip"
            maxLength={255}
            name="zip"
            placeholder="Zip"
            value={values.zip}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.zip}
            touched={touched.zip}
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Button disabled={isSubmitting || !dirty} fullWidth type="submit">
            {isSubmitting
              ? hasAddress
                ? "Saving..."
                : "Adding..."
              : hasAddress
              ? "Save Changes"
              : "Add Address"}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
