import styled from "styled-components"
import { Button } from "components/UI/Button/Button"

// TODO: add "delete" color to button in Sanity
export const DeleteButton = styled(Button)`
  background: #df5b10;
  &:hover {
    background: #df5b10;
  }
`
