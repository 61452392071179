import { useEffect, RefObject, useCallback } from "react"

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: () => void
) => {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node
      if (ref.current && !ref.current.contains(target)) {
        callback()
      }
    },
    [ref, callback]
  )
  const createListeners = useCallback(() => {
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [handleClick])
  useEffect(createListeners, [createListeners])
}
