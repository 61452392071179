const posOffset = {
  square: "-1px",
  rounded: "8px",
  oval: "8px",
}

export type UIRounding = keyof typeof posOffset

const getPosition = (uiRounding: UIRounding) =>
  posOffset[uiRounding] ? `calc(100% + ${posOffset[uiRounding]})` : "100%"

export const positions = [
  {
    position: "top left",
    getStyles: (uiRounding: UIRounding) => ({
      left: 0,
      bottom: getPosition(uiRounding),
    }),
  },
  {
    position: "top center",
    getStyles: (uiRounding: UIRounding) => ({
      left: "50%",
      bottom: getPosition(uiRounding),
      transform: "translateX(-50%)",
    }),
  },
  {
    position: "top right",
    getStyles: (uiRounding: UIRounding) => ({
      right: 0,
      bottom: getPosition(uiRounding),
    }),
  },
  {
    position: "right top",
    getStyles: (uiRounding: UIRounding) => ({
      top: 0,
      left: getPosition(uiRounding),
    }),
  },
  {
    position: "right center",
    getStyles: (uiRounding: UIRounding) => ({
      left: getPosition(uiRounding),
      top: "50%",
      transform: "translateY(-50%)",
    }),
  },
  {
    position: "right bottom",
    getStyles: (uiRounding: UIRounding) => ({
      left: getPosition(uiRounding),
      bottom: 0,
    }),
  },
  {
    position: "bottom left",
    getStyles: (uiRounding: UIRounding) => ({
      top: getPosition(uiRounding),
      left: 0,
    }),
  },
  {
    position: "bottom center",
    getStyles: (uiRounding: UIRounding) => ({
      top: getPosition(uiRounding),
      left: "50%",
      transform: "translateX(-50%)",
    }),
  },
  {
    position: "bottom right",
    getStyles: (uiRounding: UIRounding) => ({
      top: getPosition(uiRounding),
      right: 0,
    }),
  },
  {
    position: "left top",
    getStyles: (uiRounding: UIRounding) => ({
      right: getPosition(uiRounding),
      top: 0,
    }),
  },
  {
    position: "left center",
    getStyles: (uiRounding: UIRounding) => ({
      right: getPosition(uiRounding),
      top: "50%",
      transform: "translateY(-50%)",
    }),
  },
  {
    position: "left bottom",
    getStyles: (uiRounding: UIRounding) => ({
      right: getPosition(uiRounding),
      bottom: 0,
    }),
  },
]
