import { useAuth0, User } from "@auth0/auth0-react"
import { Button } from "components/UI/Button/Button"
import { Config } from "config/brands"
import { Form, Formik } from "formik"
import React, { useState } from "react"
import styled from "styled-components"
import { typography } from "styles"
import { userSchema } from "validationSchemas/userSchema"
import { TextInput } from "./TextInput"

export const ErrorMessage = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.dark?.text?.formError?.opacityHex};
  padding: 10px;
`

type UserFormProps = {
  onSuccess: () => void
}

export const UserForm: React.FC<UserFormProps> = ({ onSuccess }) => {
  const { user, getAccessTokenSilently } = useAuth0()
  const [userData, setUserData] = useState<User>()
  const [errorMessage, setErrorMessage] = useState("")
  const initialValues = {
    firstName: user.given_name,
    lastName: user.family_name,
  }

  const updateUser = async ({
    firstName,
    lastName,
  }: {
    firstName: string
    lastName: string
  }) => {
    const token = await getAccessTokenSilently()

    const response = await fetch("/api/user", {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        given_name: firstName,
        family_name: lastName,
      }),
    })

    if (response.status >= 400) {
      console.error(response)

      throw new Error("Error saving user")
    } else {
      user[Config.auth0.metaDataNamespace + "user"] = userData

      // We need this so the locally cached idToken is updated.
      // The idToken contains the user data including the address.
      // If we don't update the idToken, then the address changes will be lost on page refresh
      await getAccessTokenSilently({ ignoreCache: true })

      onSuccess()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={userSchema}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={async (updatedUserData, actions) => {
        try {
          setErrorMessage("")
          await updateUser(updatedUserData)
          setUserData(updatedUserData)
        } catch (error) {
          setErrorMessage("Could not save user. Please try again later.")
        }
      }}
    >
      {({
        dirty,
        handleChange,
        handleBlur,
        isSubmitting,
        values,
        touched,
        errors,
      }) => (
        <Form>
          <TextInput
            id="firstName"
            name="firstName"
            value={values.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.firstName}
            touched={touched.firstName}
            placeholder="First Name"
            maxLength={255}
          />
          <TextInput
            id="lastName"
            name="lastName"
            value={values.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.lastName}
            touched={touched.lastName}
            placeholder="Last Name"
            maxLength={255}
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Button disabled={isSubmitting || !dirty} type="submit" fullWidth>
            {isSubmitting ? "Saving..." : "Save Changes"}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
