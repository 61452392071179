import { useAuth0 } from "@auth0/auth0-react"
import { routes } from "constants/routes"
import React, { useState } from "react"
import { loggedWithEmail } from "utils/user"
import { Content, Wrapper } from "./AccountView.styles"
import {
  Card,
  CardButton,
  CardContent,
  CardHeader,
  CardLink,
  CardTitle,
  UserData,
} from "./Card.styles"
import { Header } from "./Header"
import { UserForm } from "./UserForm"

// TODO: Make dynamic messages based on login type and state

export const Profile: React.FC<{ path?: string }> = () => {
  const [isEditing, setIsEditing] = useState(false)
  const { user } = useAuth0()
  const { given_name: givenName, family_name: familyName, email } = user

  const UserDetails = React.forwardRef<HTMLDivElement>((props, ref) => (
    <div>
      <UserData>
        {givenName} {familyName}
      </UserData>
      <UserData>{email}</UserData>
    </div>
  ))

  return (
    <Wrapper>
      <Header heading="Profile">
        {givenName ? `Hi ${givenName}. ` : null}
        {loggedWithEmail(user) ? (
          <>
            Want to change your password? Update it{" "}
            <CardLink to={routes.account.changePassword}>here</CardLink>.
          </>
        ) : null}
      </Header>
      <Content>
        <Card>
          <CardHeader>
            <CardTitle>Your Information</CardTitle>
            <CardButton onClick={() => setIsEditing(!isEditing)}>
              {isEditing ? "Cancel" : "Edit"}
            </CardButton>
          </CardHeader>
          <CardContent>
            {isEditing ? (
              <UserForm onSuccess={() => setIsEditing(false)} />
            ) : (
              <UserDetails />
            )}
          </CardContent>
        </Card>
      </Content>
    </Wrapper>
  )
}
