import React from "react"
import { addSlash } from "utils/stringUtils"
import { TabsNav, TabsNavLink, TabsNavItem, TabsWrapper } from "./Tabs.styles"

export type Tab = {
  id: number
  name: string
  path: string
  component: React.FC<{ path: string }>
}

type TabsProps = {
  tabs: Tab[]
}

export const Tabs: React.FC<TabsProps> = ({ tabs, children }) => {
  return (
    <TabsWrapper>
      <TabsNav>
        {tabs.map(({ id, name, path }) => (
          <TabsNavItem key={id}>
            <TabsNavLink
              tabIndex={0}
              to={path}
              getProps={({ href, location }) => {
                if (addSlash(href) === addSlash(location.pathname)) {
                  return {
                    "data-active": true,
                  }
                }
                return {}
              }}
            >
              {name}
            </TabsNavLink>
          </TabsNavItem>
        ))}
      </TabsNav>
      {children}
    </TabsWrapper>
  )
}
