import { AddressItem, CardBody } from "components/Account/Card.styles"
import { ColumnsWrapper } from "components/Account/Form.styles"
import { Subtotal, Total, TotalsContainer } from "components/Cart/Cart.styles"
import {
  ImageContainer,
  Item,
  MiddleContainer,
} from "components/Cart/LineItem.styles"
import styled from "styled-components"
import { mq, typography } from "styles"

export const OrderOverview = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
export const OrderMeta = styled.div`
  line-height: 22px;
`
export const OrderBody = styled(CardBody)`
  margin-bottom: 0;
`
export const OrderList = styled.div`
  width: 100%;
  margin-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.dark?.ui?.divider.opacityHex};
`
export const OrderItem = styled(Item)`
  align-items: center;
  padding: 16px 0;
  display: grid;
  grid-template-columns: auto 1fr;
`
export const OrderMiddleContainer = styled(MiddleContainer)`
  align-items: flex-end;
`
export const OrderTotalsContainer = styled(TotalsContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.dark?.ui?.divider.opacityHex};
  padding: 24px 0;
`
export const OrderImageContainer = styled(ImageContainer)`
  align-self: flex-start;
  margin-right: 16px;
  width: 44px;
  ${mq.minWidth("md")} {
    width: 64px;
  }
`
export const OrderSubtotal = styled(Subtotal)`
  ${typography.bodySmall};
  margin-bottom: 4px;
  span {
    color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  }
`
export const OrderTotal = styled(Total)`
  font-weight: bold;
`
export const OrderColumnsWrapper = styled(ColumnsWrapper)`
  padding: 24px 0 32px;
`
export const OrderAddressItem = styled(AddressItem)`
  ${typography.bodySmall};
`
export const TrackOrder = styled.div`
  display: flex;
  align-items: center;
`
export const OrderTrackWrapper = styled(ColumnsWrapper)`
  align-items: center;
  grid-gap: 24px;
  button {
    width: 100%;
    ${mq.minWidth("md")} {
      width: auto;
    }
  }
`
export const OrderTrackContact = styled.div`
  padding-bottom: 32px;

  ${mq.minWidth("md")} {
    padding-bottom: 0;
  }
`

export const OrderStatusText = styled.span<{ $status: string }>`
  color: ${({ $status, theme }) => {
    switch ($status) {
      case "Delivered":
      case "Fulfilled":
        return theme.dark.text?.formSuccess?.opacityHex
      case "Returned":
        return theme.dark.text?.formError?.opacityHex
      default:
        return theme.dark.text?.primary?.opacityHex
    }
  }};
`
