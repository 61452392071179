import { animations, typography } from "styles"
import DownArrow from "assets/svg/caret-down.svg"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import styled from "styled-components"

type IsFlipped = { $isFlipped: boolean }

export const MenuButton = styled(NoStyleButton)`
  width: 100%;
  height: 100%;
  padding: 1px;
  border: 1px solid ${({ theme }) => theme.global.dropdown?.stroke?.opacityHex};
  border-radius: var(--ui-rounding);
  &:focus-visible {
    outline: 0;
    padding: 0;
    border: 2px solid var(--tab-stroke);
  }
  transition: background 300ms ${animations.defaultBezier};
  &:hover {
    background: ${({ theme }) => theme.global.dropdown?.hoverFill?.opacityHex};
  }
`

export const Current = styled.div`
  color: ${({ theme }) => theme.global.dropdown?.primaryText?.opacityHex};
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  cursor: pointer;
`

export const CurrentLeft = styled.div`
  ${typography.bodySmall};
  display: flex;
  align-items: center;
  padding-right: 8px;
`

export const StyledArrow = styled(DownArrow)<IsFlipped>`
  color: ${({ theme }) => theme.global.dropdown?.chevron?.opacityHex};
  transform: ${({ $isFlipped }) => ($isFlipped ? "rotate(180deg)" : "")};
`
